<template>
    <div>
        <div class="navbar">
            <van-nav-bar @click-left="$router.push('assets')" left-arrow class="normal" safe-area-inset-top :border="false">
                <template #title>
                    <van-tabs v-model="content" title-active-color="#3861FB" color="#3861FB">
                        <van-tab v-for="i in tabList" :title="i" :key="i"></van-tab>
                    </van-tabs>
                </template>
            </van-nav-bar>
        </div>

        <div class="content">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <template v-if="content === 0">
                </template>
                <template v-if="content === 1">
                    <div class="list-cell" v-for="item in tableWithdraw" :key="item.id">
                        <div class="justify-between">
                            <span class="title">{{ item.coin.name }}</span>
                            <span>{{ formatStatus(item.status) }}</span>
                        </div>
                        <div class="justify-between">
                            <span class="label">提币时间</span>
                            <span class="value">{{ item.createTime }}</span>
                        </div>
                        <div class="justify-between">
                            <span class="label">提币地址</span>
                            <span class="value">{{ address2simple(item.address, 11) }}</span>
                        </div>
                        <div class="justify-between">
                            <span class="label">提币数量</span>
                            <span class="value">{{ item.totalAmount }}</span>
                        </div>
                        <div class="justify-between">
                            <span class="label">手续费</span>
                            <span class="value">{{ item.fee }}</span>
                        </div>
                    </div>
                </template>
                <template v-if="content === 2">
                    <div class="list-cell" v-for="item in tableTrading" :key="item.id">
                        <div class="justify-between">
                            <span class="label">类型</span>
                            <span>{{ translateType(item.type) }}</span>
                        </div>
                        <div class="justify-between">
                            <span class="label">币种</span>
                            <span class="value">{{ item.symbol }}</span>
                        </div>
                        <div class="justify-between">
                            <span class="label">交易数量</span>
                            <span class="value">{{ item.amount }}</span>
                        </div>
                        <div class="justify-between">
                            <span class="label">交易时间</span>
                            <span class="value">{{ item.createTime }}</span>
                        </div>
                    </div>
                </template>
            </van-list>
        </div>
    </div>
</template>
<script>
import { map, mapEn } from './utils/deal'
export default {
    data() {
        return {
            content: 0,
            tabList: [this.$t('form.assets.cbResult'), this.$t('form.assets.tbResult'), this.$t('form.assets.transaction_records')],
            tableWithdraw: [],
            tableTrading: [],
            loading: false,
            finished: false,
            transaction: {
                page: 0,
                pageSize: 10
            },
            tradingRecord: {
                pageNo: 1
            }
        }
    },
    watch: {
        content(newVal, oldVal) {
            !this.$store.state.isLogin && this.$router.push('/signin');
            this.finished = false;
            if (newVal === 0) {

            } else if (newVal === 1) {
                this.getWithdrawalRecord();
            } else if (newVal === 2) {
                this.getTradingRecord();
            }
        }
    },
    mounted() {

    },
    methods: {
        address2simple(address, cut) {
            let length = address.length;
            if (!address || length < cut * 2) {
                return "";
            }
            let prefix = address.substring(0, cut);
            let suffix = address.substring(length - cut, length);
            return `${prefix}...${suffix}`;
        },
        //获取提币记录
        getWithdrawalRecord(page = 1) {
            this.loading = true;
            let params = {};
            params['page'] = page;
            params['pageSize'] = this.transaction.pageSize;
            this.$http.post(this.host + '/uc/withdraw/record', params).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.tableWithdraw = this.tableWithdraw.concat(resp.data.content);

                    const uniqueArr = this.tableWithdraw.reduce((acc, current) => {
                        const x = acc.find(item => item.id === current.id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);

                    this.tableWithdraw = uniqueArr;
                    if (page >= resp.data.totalPages) {
                        this.finished = true;
                    }
                } else {
                    this.$toast(resp.message);
                }
                this.loading = false;
            });
        },
        // 获取交易记录
        getTradingRecord(pageNo = 1) {
            this.loading = true;
            let params = {
                pageNo,
                pageSize: 10,
                startTime: '',
                endTime: '',
                memberId: JSON.parse(localStorage.getItem('user')).id,
                symbol: '',
                type: ''
            };
            this.$http.post(this.host + '/uc/asset/transaction', params).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.tableTrading = this.tableTrading.concat(resp.data.content);
                    const uniqueArr = this.tableTrading.reduce((acc, current) => {
                        const x = acc.find(item => item.id === current.id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);
                    this.tableTrading = uniqueArr;
                    if (pageNo >= resp.data.totalPages) {
                        this.finished = true;
                    }

                } else {
                    this.$toast(resp.message);
                }
                this.loading = false;
            });
        },
        onLoad() {
            if (this.content === 0) {

            } else if (this.content === 1) {
                this.transaction.page++;
                this.getWithdrawalRecord(this.transaction.page);
            } else if (this.content === 2) {
                this.tradingRecord.pageNo++;
                this.getTradingRecord(this.tradingRecord.pageNo);
            }
        },

        formatStatus(status) {
            let text = '';
            if (status == 0) {
                text = '审核中';
            } else if (status == 1) {
                text = '转账中';
            } else if (status == 2) {
                text = '失败';
            } else if (status == 3) {
                text = '成功';
            }
            return text;
        },
        translateType(type) {
            const m = localStorage.getItem('language') == 'en-US' ? mapEn : map;
            return m.get(type + '');
        },
    }
}
</script>
<style>
.content {
    padding: 0 3.8168vw;
    box-sizing: border-box;
}

.list-cell {
    padding: 2.5445vw 3.5623vw;
    background: #FFFFFF;
    border-radius: 2.0356vw;
    margin-top: 3.8168vw;
}

.justify-between {
    margin-top: 2.2901vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-cell .title {
    color: #0D1421;
    font-size: 4.0712vw;
    font-weight: bold;
}

.list-cell .label {
    color: #616E85;
    font-size: 3.5623vw;
}

.list-cell .value {
    color: #3861FB;
    font-size: 3.5623vw;
}
</style>