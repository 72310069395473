export const map = new Map([
    ['0', '充值'],
    ['1', '提现'],
    ['2', '转账'],
    ['3', '币币交易'],
    ['4', '法币买入'],
    ['5', '法币卖出'],
    ['6', '活动奖励'],
    ['7', '推广奖励'],
    ['8', '分红'],
    ['9', '投票'],
    ['10', '人工充值'],
    ['11', '配对'],
    ['12', '缴纳商家认证保证金'],
    ['13', '退回商家认证保证金'],
    ['14', '法币充值'],
    ['15', '币币兑换'],
    ['16', '渠道推广'],
    ['17', '划转入杠杆钱包'],
    ['18', '从杠杆钱包划转出'],
    ['19', '钱包空投'],
    ['20', '锁仓'],
    ['21', '解锁'],
    ['22', '第三方转入'],
    ['23', '第三方转出'],
    ['24', '币币转入法币'],
    ['25', '法币转入币币'],
    ['26', '借贷流水'],
    ['27', '还款流水'],
    ['28', '币币转入合约'],
    ['29', '合约转入币币'],
    ['30', '合约强制平仓'],
    ['31', '合约手动平仓'],
    ['32', '合约一键平仓'],
    ['33', '合约开仓'],
    ['34', '跟单返佣'],
    ['35', '隔夜费'],
    ['36', '期货转入法币'],
    ['37', '法币转入期货'],
    ['38', '期货交易'],
    ['39', '期货盈利'],
    ['40', '期货亏损'],
    ['41', '期货人工充值'],
    ['42', 'OTC人工充值'],
    ['43', '投资理财'],
    ['44', '退回商家投资款'],
    ['45', '释放质押'],
    ['46', '活期收益'],
    ['47', '30天定期收益'],
    ['48', '60天定期收益'],
    ['49', '90天定期收益'],
    ['50', '180天定期收益'],
    ['51', '360天定期收益'],
    ['52', '体验金币币转入合约'],
    ['53', '体验金合约转入币币'],
    ['54', '币币体验金提现']
]);

export const mapEn = new Map([
    ['0', 'recharge'],
    ['1', 'withdraw deposit'],
    ['2', 'transfer'],
    ['3', 'coin deal'],
    ['4', 'legal tender buying'],
    ['5', 'legal tender selling'],
    ['6', 'activity reward'],
    ['7', 'Promotion rewards'],
    ['8', 'dividend'],
    ['9', 'voting'],
    ['10', 'Artificial Recharge'],
    ['11', 'pairing'],
    ['12', 'pay the merchant certification deposit'],
    ['13', 'Return the merchant certification deposit'],
    ['14', 'legal tender recharge'],
    ['15', 'currency exchange'],
    ['16', 'Channel promotion'],
    ['17', 'Transfer to lever wallet'],
    ['18', 'Transfer out of the lever wallet'],
    ['19', 'wallet airdrop'],
    ['20', 'locked position'],
    ['21', 'unlock'],
    ['22', 'Third party transfer'],
    ['23', 'Third party roll out'],
    ['24', 'The bitcoins are transferred into legal tender'],
    ['25', 'Legal tender is transferred into bitcoin'],
    ['26', 'loan flow'],
    ['27', 'repayment flow'],
    ['28', 'COIN_TWO_CONTRACT'],
    ['29', 'CONTRACT_TWO_COIN'],
    ['30', 'CONTRACT_FORCE_CLOSE'],
    ['31', 'CONTRACT_CLOSE'],
    ['32', 'CONTRACT_FAST_CLOSE'],
    ['33', 'CONTRACT_OPEN'],
    ['34', 'FOLLOW_AWARD'],
    ['35', 'OVERNIGHT_FEE'],
    ['46', 'Current_income'],
    ['47', '30DAY_Regular_income'],
    ['48', '60DAY_Regular_income'],
    ['49', '90DAY_Regular_income'],
    ['50', '180DAY_Regular_income'],
    ['51', '360DAY_Regular_income'],
    ['52', '体验金币币转入合约'],
    ['53', '体验金合约转入币币'],
    ['54', '币币体验金提现']
]);